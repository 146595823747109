import "react-slidy/lib/styles.css";
import "./src/theme/fx-sans.css";
import "./src/theme/global-styles.css";

export const onRouteUpdate = ({ location }) => scrollToAnchor(location);

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail

  if (location && location.hash) {
    console.log("location:", location);
    setTimeout(() => {
      const item = document.querySelector(`${location.hash}`);

      if (item) {
        console.log("item found", item);
        document.querySelector("#scroll-container")?.scrollTo({
          //@ts-ignore
          top: item.offsetTop - 60,
        });
      }
    }, 1000);
  }

  return true;
}
